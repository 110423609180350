/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nabízíme"} description={"Základní přehled nabídky"}>
        <Column className="pt--10" name={"oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"3m8j3ygak8s"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--stretch" style={{"paddingLeft":20,"paddingRight":1011}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image className="--left" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/9d22080093134e53a4ccd7d031dcf5de_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/d9e1f22aa15c4747b4fc82279114a3f9_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"nabizime-hlavicka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} parallax={false}>
          
          <Menu style={{"maxWidth":1300,"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":125}} target={""} content={"poradna"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":125}} target={""} content={"galerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"aktuality"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"nabizime_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"nabizime-oddelovac-1"}>
          
          <SeparateLineWrap fullscreen={false}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"nabizime-rozcestnik"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1160}} columns={"3"}>
            
            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"KAMNA<br><br>"}>
              </Title>

              <Text className="text-box mt--16" content={"Kvalitní topidlo, které vám zajistí tepelný komfort po celou zimu. Lahodí oku a hřeje nejen na duši."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/kamna"} target={null} content={"Více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"SPORÁKY<br><br>"}>
              </Title>

              <Text className="text-box mt--16" content={"Vaření jako u babičky? Přesvědčte se, že sporáky nejsou pouhá dávná nostalgie.<br><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/sporaky"} target={null} content={"více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"KOMBINOVANÁ TOPIDLA"}>
              </Title>

              <Text className="text-box mt--16" content={"Nemůžete se rozhodnout, jestli chcete vytápět nebo vařit a nejraději byste vše najednou? Pak pro vás máme dobrou zprávu. Jde to!"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/kombinovana_topidla"} target={null} content={"ve výstavbě..."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"KRBY"}>
              </Title>

              <Text className="text-box mt--16" content={"Také se rádi díváte do ohně a nasloucháte praskání dřeva? <br>Krb je ta správná volba."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/krby"} target={null} content={"Více"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"KOMÍNY"}>
              </Title>

              <Text className="text-box mt--16" content={"Ani sebelepší topidlo nebude fungovat bez kvalitního správně dimenzovaného komínu. I tady máme řešení...<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/kominy"} target={null} content={"ve výstavbě..."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--style1">
              
              <Title className="W--400 title-box title-box--style1" content={"SPECIALITY"}>
              </Title>

              <Text className="text-box mt--16" content={"Křupavá pizza z pece, zelené rostlinky ve vytápěném skleníku? Nic není nemožné...<br><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 btn-box--invert swpf--uppercase mt--16" href={"/speciality"} target={null} content={"více"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" name={"nabizime-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-xn7sl8 --parallax pb--10 pt--10" name={"nabizime-odkaz-poradna"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mb--10 mt--10" style={{"maxWidth":800,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box" content={"JAKÉ TOPIDLO VLASTNĚ CHCI A PROČ?"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 mt--02" content={"Aneb průvodce po světě kamnařiny."}>
              </Subtitle>

              <Text className="text-box" content={"Rozhodli jste se pořídit si domů \"topení\". Jak ale zjistit, který druh topidla je vhodný právě pro vaše podmínky? Co vás čeká, když oslovíte kamnáře a na co se připravit? "}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mb--10 mt--20" href={"/poradna"} target={null} content={"TO MĚ ZAJÍMÁ!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-xn7sl8 bg--center --parallax pb--80 pt--80" name={"nabizime-konzultace"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--style1 pb--40 pl--10 pr--10 pt--40" style={{"maxWidth":520,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"KAMNÁŘSTVÍ <br>NA ROZKOŠI"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)","maxWidth":380}} content={"Rodinné kamnářství na břehu vodní nádrže Rozkoš. Přijďte si k nám pro návrh šitý vám na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 swpf--uppercase mt--16" href={"/kontakt"} target={null} content={"konzultace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"nabizime-oddelovac-5"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"nabizime-zapati"}>
          
          <Menu className="--left flex--top" style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box" style={{"maxWidth":417}}>
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--sbtn1 btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={""} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={""} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"nabizime-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOv NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"nabizime-oddelovac-6"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"nabizime-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}